import React from "react"
import PropTypes from "prop-types"

import { withStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"

import Typography from "./Utils/Typography"

import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';
/* import MoreOutlinedIcon from '@material-ui/icons/MoreOutlined'; */

const styles = theme => ({
  root: {
    display: "flex",
    backgroundColor: theme.palette.secondary.light,
    overflow: "hidden",
  },
  container: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(15),
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  item: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(0, 5),
  },
  title: {
    marginBottom: theme.spacing(14),
  },
  number: {
    fontSize: 24,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightMedium,
  },
  image: {
    height: 55,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
/*   curvyLines: {
    pointerEvents: "none",
    position: "absolute",
    top: -180,
    opacity: 0.7,
  }, */
  button: {
    marginTop: theme.spacing(8),
  },
})

function EmsculptNeoPreise(props) {
  const { classes } = props

  return (
    <section className={classes.root} id="EMSCULPT-NEO-PREISE" aria-label="Öffnungszeiten, Behandlungspreise und Optionen zu EMSCULPT NEO">
      <Container className={classes.container}>

        <Typography
          variant="h4"
          marked="center"
          className={classes.title}
          component="h2"
        >
         EMSCULPT NEO® in Bremen Privatpraxis ESTETIESE
        </Typography>
        <div>
          <Grid container spacing={5}>
            <Grid item xs={12} md={3}>
              <div className={classes.item}>
                <CalendarTodayOutlinedIcon/>Termine
                <Typography variant="h5" align="center">
                  Montag - Freitag <br/>09:00 - 18:00 Uhr
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
            <div className={classes.item}>                
                <EuroSymbolIcon/>Preise Arme,Bauch, Hüften und Po<br/> pro 30 Minuten Sitzung
                <Typography variant="h5" align="center">
                
                Triceps: ab 122,50 €<br/>
                Biceps: ab 122,50 €<br/>
                Bauch: ab 300 €<br/>
                Po: ab 300 €<br/>
                Hüften: ab 300 €<br/>        
                <br/>  
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} md={5}>
            <div className={classes.item}>                
                <EuroSymbolIcon/>Preise Beine<br/> pro 30 Minuten Sitzung
                <Typography variant="h5" align="center">                 
                Oberschenkel innen: ab 187,50 €<br/>
                Oberschenkel außen: ab 187,50 €<br/>
                Oberschenkel vorne: ab 162,50 €<br/>
                Oberschenkel hinten: ab 162,50 €<br/>
                Waden: ab 122,50 €<br/>
                <br/>  
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} md={12}>
              <div className={classes.item}>                
              <Typography variant="h5" align="center">
              Es werden 4-6 Sitzungen á 30 Minuten empfohlen.<br/>Alle Kosten inklusive Mehrwertsteuer. Die Abrechnung erfolgt nach ärztlicher Gebührenordnung (GOÄ)
                </Typography>
              </div>
            </Grid>
          </Grid>
        </div>

      </Container>
    </section>
  )
}

EmsculptNeoPreise.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(EmsculptNeoPreise)
