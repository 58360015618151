import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import ButtonBase from "@material-ui/core/ButtonBase"
import Container from "@material-ui/core/Container"
import Typography from "./Utils/Typography"
import Image from "./Image"


const styles = theme => ({
  root: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(4),
  },
  images: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexWrap: "wrap",
  },
  imageWrapper: {
    position: "relative",
    display: "block",
    padding: 0,
    borderRadius: 0,
    height: "40vh",
    [theme.breakpoints.down("sm")]: {
      width: "100% !important",
      height: 240,
    },
    "&:hover": {
      zIndex: 1,
    },
    "&:hover $imageBackdrop": {
      opacity: 0.15,
    },
    "&:hover $imageMarked": {
      opacity: 0,
    },
    "&:hover $imageTitle": {
      border: "4px solid currentColor",
    },
  },
  imageButton: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.common.white,
  },
  imageSrc: {
    position: "absolute !important",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundPosition: "center 40%",
  },
  imageBackdrop: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    background: theme.palette.common.black,
    opacity: 0.5,
    transition: theme.transitions.create("opacity"),    
  },
  imageTitle: {
    position: "relative",
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px 14px`,
  },
  imageMarked: {
    height: 3,
    width: 18,
    background: theme.palette.common.white,
    position: "absolute",
    bottom: -2,
    left: "calc(50% - 9px)",
    transition: theme.transitions.create("opacity"),
  },
})

function EmsculptNeoImpressionen(props) {
  const { classes } = props

  const images = [
    {
      fname:
      "Emsculpt_PIC_Female-model-natural-colors_2334.jpg",
      url:
      "images/Emsculpt_PIC_Female-model-natural-colors_2334.jpg",
      title: "Bauch",
      width: "40%",
    },
    {
      fname:
        "Emsculpt_PIC_Female-model-natural-colors-no-crack_2429.jpg",
      url:
        "images/Emsculpt_PIC_Female-model-natural-colors-no-crack_2429.jpg",
      title: "Beine und Arme",
      width: "20%",
    },
    {
      fname:
      "Emsculpt_PIC_Model-montage-web_2283.jpg",
      url:
        "images/Emsculpt_PIC_Female-model-natural-colors-no-crack.jpg",
      title: "Po",
      width: "20%",
    },
    {
      fname:
      "Emsculpt_PIC_pack-1_EN100.png",
      url:
        "images/Emsculpt_PIC_pack-1_EN100.png",
      title: "Paare",
      width: "20%",
    },
    {
      fname:
      "Emsculpt_POST_August-Calendar_11082020_EN100.png",
      url:
      "images/Emsculpt_POST_August-Calendar_11082020_EN100.png",
      title: "Wirksam",
      width: "50%",
    },
    {
      fname:
      "Emsculpt_POST_September-Calendar_22092020_EN100.png",
      url:
        "images/Emsculpt_POST_September-Calendar_22092020_EN100.png",
      title: "Stressfrei",
      width: "50%",
    },
    {
      fname:
      "41.jpg",
      url:
      "images/41.jpg",
      title: "Sicher",
      width: "33%",
    },
    {
      fname:
      "EMSculpt_stressfrei.jpg",
      url:
      "images/EMSculpt_stressfrei.jpg",
      title: "stressfrei",
      width: "34%",
    },
    {
      fname:
      "51.jpg",
      url:
      "images/51.jpg",
      title: "in Bremen bei ESTETIESE",
      width: "33%",
    },
  ]

  return (
    <Container className={classes.root} component="section" id="EMSCULPT-NEO-IMPRESSIONEN" aria-label="Bilder zu EMSCULT NEO: Anwendungen und Merkmale">
      <Typography variant="h4" marked="center" align="center" component="h2">
        Für einen schönen und sportlichen Körper
      </Typography>


      <div className={classes.images}>
        {images.map(image => (
          <ButtonBase
            key={image.title}
            className={classes.imageWrapper}
            style={{
              width: image.width,
            }}
          >
{/*            <div
              className={classes.imageSrc}
               style={{
                backgroundImage: `url(${image.url})`,
              }} 
            />  */}
            
            <Image src={image.fname} alt={image.title} className={classes.imageSrc}/>

            <div className={classes.imageBackdrop} />
            <div className={classes.imageButton}>
              <Typography
                component="h3"
                variant="h6"
                color="inherit"
                className={classes.imageTitle}
              >
                {image.title} 
                <div className={classes.imageMarked} />
              </Typography>
            </div>
          </ButtonBase>          
        ))}
      </div>
    </Container>
  )
}

EmsculptNeoImpressionen.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(EmsculptNeoImpressionen)
