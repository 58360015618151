import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import Accordion from "@material-ui/core/Accordion"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import Container from "@material-ui/core/Container"
import Typography from "./Utils/Typography"
import { Helmet } from "react-helmet"
/* import ProductCurvyLines from "../assets/images/productCurvyLines.png" */


const styles = theme => ({
  root: {
    display: "flex",
    overflow: "hidden",
    backgroundColor: theme.palette.secondary.light,
  },
 container: {
    marginTop: theme.spacing(15),
    marginBottom: theme.spacing(30),
    display: "block",    
    position: "relative",
    width: "80%",
  },
  item: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: theme.spacing(0, 5),    
  },
  image: {
    height: 55,
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
/*   curvyLines: {
    pointerEvents: "none",
    position: "absolute",
    top: -180,
  }, */
})

var faq_data = 
{
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "Warum ist EMSCULPT NEO das herausragende System im Bereich Körperformung?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "EMSCULPT NEO kombiniert als erstes und einzigen System zwei Verfahren (HIFEM + RF), um in einer 30-minütigen Sitzung Fett abzubauen und Muskeln aufzubauen."
    }
  }, {
    "@type": "Question",
    "name": "Welche Zonen können mit EMSCULPT® NEO behandelt werden?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "EMSCULPT NEO® eignet sich zur Anwendung an Bauch, Gesäß (Po-Lifting), Oberarmen (Bizeps und Trizeps), Oberschenkeln (Innen- und Außen-, Vorder- und Rückseite) und Waden."
    }
  }, {
    "@type": "Question",
    "name": "Wie fühlt sich eine EMSCULPT NEO-Behandlung sich an?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Sie Sie werden wahrscheinlich intensive Muskelkontraktionen zusammen mit einem Wärmegefühl im behandelten Bereich spüren, das mit einer Hot-Stone-Massage vergleichbar ist."
    }
  }, {
    "@type": "Question",
    "name": "Wieviele Behandlungen sind mit EMSCULPT NEO notwendig?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Mit EMSCULPT NEO sind normalerweise vier 30-minütigen Behandlungen im Abstand von 5 bis 10 Tagen pro Region anzusetzen."
    }
  }, {
    "@type": "Question",
    "name": "Welche Risken und Nebenwirkungen gibt es?<",
    "acceptedAnswer": {
      "@type": "Answer",
      "text":"Sie sollten im Allgemeinen fit und gesund sein. EMSCULPT Neo ist nicht geeignet, wenn Sie Metallimplantate im Bereich von Bauch, Gesäß, Hüfte oder der oberen Zone der Beinehaben oder einen Herzschrittmacher tragen. Bei Frauen ist die Behandlung während einer Schwangerschaft oder bei Verwendung einer Kupferspirale zur Empfängnisverhütung nicht angebracht.       Die Behandlung kann zu leichten Rötungen oder Schwellungen an den behandelten Stellen des Körpers führen, die bald wieder abklingen.       Nach der Behandlung kann Muskelkater auftreten, der sich normalerweise schnell wieder legt."
    } 
    }
    , {
      "@type": "Question",
      "name": "Gibt es Ausfallzeiten mit EMSCULPT NEO?<",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Das Verfahren erfordert keine Ruhezeiten. Normalerweise können Sie direkt nach der Behandlung Ihrer alltäglichen Routine nachgehen."
      }
    }, 
    {
      "@type": "Question",
      "name": "Ist EMSCULPT NEO für Patienten mit BMIs von bis zu 35 geeignet?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text":"Ja. EMSculpt NEO unterstützt Sie beim Abbau von Fett und beim Aufbau von Muskeln. "}
    }, 
    {
        "@type": "Question",
        "name": "Hilft mir EMSCULPT NEO bei der Gewichtsreduktion?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text":"Nein, das EMSCULPT NEO-Verfahren allein hilft Ihnen nicht bei der Gewichtsreduktion. Die Therapie hilft, Ihr Fett zu reduzieren und Ihre Muskeln aufzubauen."}
    }, 
    {
          "@type": "Question",
          "name": "Wie vergleicht sich EMSCULPT NEO mit Fettabsaugung und nicht-invasiven Gerätealternativen?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text":"EMSCULPT NEO ist nicht mit einer Fettabsaugung zu vergleichen, weil EMSCULPT NEO ein vollständig nicht-invasives Verfahren ist.         Es gibt keine Operationen, Nadeln, Anästhesie oder Ausfallzeiten. Es gibt kein anderes Gerät im ästhetischen Bereich, das sowohl Fett als auch Muskeln in einer einzigen Behandlung behandelt."
          }
    }, 
    {
          "@type": "Question",
          "name": "Wie unterscheidet sich die EMSculpt NEO Behandlung vom EMS-Traing?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text":`Beim Elektromyostimulation (EMS) Training werden die Muskelzellen durch elektrische Reize (Strom) erregt. Dies erfolgt in der Regel
            mittels Oberflächendioden über die Haut. EMS-Training ist seit den 70er Jahren bekannt, und hat durch die oberflächliche 
            Stimulation nur eine begrenzte Wirkung, da diese Trainingsform nur die oberste Muskelschicht erreicht.
            
            Bei Emsculpt NEO kommt kein Strom zum Einsatz, sondern ein stimulierendes elektromagnetische Feld, das den ganzen Muskel durchdringt.
            Eine Behandlung mit EMSCULPT NEO bewirkt etwa 20.000 Muskelkontraktionen. 
            Die so erzielten supramaximalen Kontraktionen straffen und stärken die innere Struktur der Zielmuskeln und können im Durchschnitt eine Zunahme der Muskelmasse um 25% erreichen. Gleichzeitig sorgen sie für eine Reduktion der Fettzellen. Letzterer Effekt wird durch die zusätzliche Radiofrequenzerwärmung noch verstärkt, sodass durch die Behandlung eine Verringerung des subkutanen Fetts von bis zu 30% möglich ist.
            Diese Effekte sind durch medizinische Studien nachgewiesen.`
          }
    }, 
    {
          "@type": "Question",
          "name": "Hilft EMSCULPT NEO bei der Behandlung einer Rektusdiastase?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text":"EMSCULPT NEO reduziert den Abstand der Bauchtrennung ('abdominal separation') der geraden Bauchmuskeln um bis zu 19%. Insofern ist EMSCULPT NEO ein wirksame Methode bei Rektusdiastase nach einer Schwangeschaft oder einer Diät."}
    }
  ]
}


function EmsculptNEOFragenAntworten(props) {
  const { classes } = props;
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };


  return (
    <section className={classes.root} id="EMSCULPT-NEO-TYPISCHE-FRAGEN" aria-label="Typische Fragen und Antworten zur Behandlung mit EMSCULPT NEO">

      <Helmet> 
      <script type="application/ld+json">
        {JSON.stringify(faq_data)}
      </script>
       </Helmet>
      <Container >

                <Typography
          variant="h4"
          marked="center"
          className={classes.title}
          component="h2"
        >
         Typische Fragen zur Behandlung mit EMSCULPT NEO® 
        </Typography>
      <Accordion  expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>Warum ist EMSCULPT NEO das herausragende System im Bereich Körperformung?</Typography>
          {/* <Typography className={classes.secondaryHeading}>I am an accordion</Typography> */}
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          EMSCULPT NEO kombiniert als erstes und einzigen System zwei Verfahren (HIFEM + RF), um in einer 30-minütigen Sitzung Fett abzubauen und Muskeln aufzubauen.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion   expanded={expanded === 'panel1a'} onChange={handleChange('panel1a')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1abh-content"
          id="panel1abh-header"
        >
          <Typography className={classes.heading}>Welche Zonen können mit EMSCULPT® NEO behandelt werden?</Typography>
          {/* <Typography className={classes.secondaryHeading}>I am an accordion</Typography> */}
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          EMSCULPT NEO® eignet sich zur Anwendung an Bauch, Gesäß (Po-Lifting), Oberarmen (Bizeps und Trizeps), Oberschenkeln (Innen- und Außen-, Vorder- und Rückseite) und Waden.
          </Typography>
        </AccordionDetails>
      </Accordion>      
      <Accordion    expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography className={classes.heading}>Wie fühlt sich eine EMSCULPT NEO-Behandlung sich an?</Typography>
         </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Sie Sie werden wahrscheinlich intensive Muskelkontraktionen zusammen mit einem Wärmegefühl im behandelten Bereich spüren, 
            das mit einer Hot-Stone-Massage vergleichbar ist.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion    expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography className={classes.heading}>Wieviele Behandlungen sind mit EMSCULPT NEO notwendig?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Mit EMSCULPT NEO sind normalerweise vier 30-minütigen Behandlungen im Abstand von 5 bis 10 Tagen pro Region anzusetzen.          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion  expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography className={classes.heading}>Welche Risken und Nebenwirkungen gibt es?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Sie sollten im Allgemeinen fit und gesund sein. 
          EMSCULPT Neo ist nicht geeignet, wenn Sie Metallimplantate im Bereich von Bauch, Gesäß, Hüfte oder der oberen Zone der Beinehaben oder einen Herzschrittmacher tragen. Bei Frauen ist die Behandlung während einer Schwangerschaft oder bei Verwendung einer Kupferspirale zur Empfängnisverhütung nicht angebracht.
          Die Behandlung kann zu leichten Rötungen oder Schwellungen an den behandelten Stellen des Körpers führen, die bald wieder abklingen.
          Nach der Behandlung kann Muskelkater auftreten, der sich normalerweise schnell wieder legt.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion   expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5bh-content"
          id="panel5bh-header"
        >
          <Typography className={classes.heading}> Gibt es Ausfallzeiten mit EMSCULPT NEO?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Das Verfahren erfordert keine Ruhezeiten. Normalerweise können Sie direkt nach der Behandlung Ihrer alltäglichen Routine nachgehen.
           </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion    expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel6bh-content"
          id="panel6bh-header"
        >
          <Typography className={classes.heading}> Ist EMSCULPT NEO für Patienten mit BMIs von bis zu 35 geeignet?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Ja. EMSculpt NEO unterstützt Sie beim Abbau von Fett und beim Aufbau von Muskeln. 
           </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion  expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel7bh-content"
          id="panel7bh-header"
        >
          <Typography className={classes.heading}> Hilft mir EMSCULPT NEO bei der Gewichtsreduktion?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Nein, das EMSCULPT NEO-Verfahren allein hilft Ihnen nicht bei der Gewichtsreduktion. Die Therapie hilft, Ihr Fett zu reduzieren und Ihre Muskeln aufzubauen.
           </Typography>
        </AccordionDetails>
      </Accordion>    
      <Accordion   expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel8bh-content"
          id="panel8bh-header"
        >
          <Typography className={classes.heading}> Wie vergleicht sich EMSCULPT NEO mit Fettabsaugung und nicht-invasiven Gerätealternativen?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            EMSCULPT NEO ist nicht mit einer Fettabsaugung zu vergleichen, 
            weil EMSCULPT NEO ein vollständig nicht-invasives Verfahren ist. 
            Es gibt keine Operationen, Nadeln, Anästhesie oder Ausfallzeiten. 
            Es gibt kein anderes Gerät im ästhetischen Bereich, das sowohl Fett als auch Muskeln in einer einzigen Behandlung behandelt.
          </Typography>
        </AccordionDetails>
      </Accordion>    
      <Accordion    expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel9bh-content"
          id="panel9bh-header"
        >
          <Typography className={classes.heading}> Wie unterscheidet sich die EMSculpt NEO Behandlung vom EMS-Traing?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Beim Elektromyostimulation (EMS) Training werden die Muskelzellen durch elektrische Reize (Strom) erregt. Dies erfolgt in der Regel
          mittels Oberflächendioden über die Haut. EMS-Training ist seit den 70er Jahren bekannt, und hat durch die oberflächliche 
          Stimulation nur eine begrenzte Wirkung, da diese Trainingsform nur die oberste Muskelschicht erreicht.
          
          Bei Emsculpt NEO kommt kein Strom zum Einsatz, sondern ein stimulierendes elektromagnetische Feld, das den ganzen Muskel durchdringt.
          Eine Behandlung mit EMSCULPT NEO bewirkt etwa 20.000 Muskelkontraktionen. 
          Die so erzielten supramaximalen Kontraktionen straffen und stärken die innere Struktur der Zielmuskeln und können im Durchschnitt eine Zunahme der Muskelmasse um 25% erreichen. Gleichzeitig sorgen sie für eine Reduktion der Fettzellen. Letzterer Effekt wird durch die zusätzliche Radiofrequenzerwärmung noch verstärkt, sodass durch die Behandlung eine Verringerung des subkutanen Fetts von bis zu 30% möglich ist.
          Diese Effekte sind durch medizinische Studien nachgewiesen.
          </Typography>
        </AccordionDetails>
      </Accordion> 
      <Accordion    expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel10bh-content"
          id="panel10bh-header"
        >
          <Typography className={classes.heading}> Hilft EMSCULPT NEO bei der Behandlung einer Rektusdiastase?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          EMSCULPT NEO reduziert den Abstand der Bauchtrennung ("abdominal separation") der geraden Bauchmuskeln um bis zu 19%. Insofern ist EMSCULPT NEO ein wirksame Methode bei Rektusdiastase nach einer Schwangeschaft oder einer Diät. 
          </Typography>
        </AccordionDetails>
      </Accordion>                             
    </Container>
    </section>
  )
}

EmsculptNEOFragenAntworten.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(EmsculptNEOFragenAntworten)
