import React, { useEffect } from "react"
import PropTypes from "prop-types"
//import { Link } from "gatsby"
import { useTheme,withStyles } from "@material-ui/core/styles"
import Button from "./Utils/Button"
import Typography from "./Utils/Typography"
import ProductHeroLayout from "./ProductHeroLayout"
import PoolVideo from "../assets/videos/EMScuplt_lowres.webm"
import PoolVideoAlt from "../assets/videos/EMScuplt_lowres.mp4"
import PoolPlaceholder from "../assets/images/dynamicVidPlaceholder.jpg"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import CallIcon from '@material-ui/icons/Call'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import Image from "./Image"


/* const backgroundImage =
  "../assets/images/dynamicVidPlaceholder.jpg" */

const styles = theme => ({
  background: {
    // backgroundImage: `url(${backgroundImage})`,
    backgroundImage: {PoolPlaceholder},
    backgroundColor: "#7fc7d9", // Average color of the background image.
    backgroundPosition: "center",
  },
  button: {
    minWidth: 200,
  },
  h1: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(10),      
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 40,      
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 24,      
    },    
  },
  h2: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(10),      
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 40,      
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 24,      
    },    
  },
  h5: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(10),
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 36,      
    },   
    [theme.breakpoints.down("sm")]: {
      fontSize: 18,      
    },      
  },
  more: {
    marginTop: theme.spacing(2),
  },
  dynamicBackground: {
    backgroundImage: `url(${PoolPlaceholder})`,
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#03a9f4",
    mixBlendMode: "overlay",
  },
  dynamicVideo: {
    zIndex: -1,
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    objectFit: "fill",
    opacity: 0.8,
  },
})

function ProductHero(props) {
  const { classes, dynamic } = props
  const [initiated, setInitiated] = React.useState(false)
  const theme = useTheme()
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"))
  useEffect(() => {
    setInitiated(true)
  }, [])

  return (
    <ProductHeroLayout
      backgroundClassName={
        dynamic ? classes.dynamicBackground : classes.background
      }
    >

      <Image src="dynamicVidPlaceholder.jpg" alt="Schnelles Laden ..." style={{ display: "none" }} />
      {/* Increase the network loading priority of the background image. */}
      {!dynamic && (

        <Image src="dynamicVidPlaceholder.jpg" alt="Schnelles Laden ..." style={{ display: "none" }} />
      )}
      {dynamic && (
        <>
          {!initiated && (
            <img
              src={PoolPlaceholder}
              alt="EMSculpt NEO video"
              className={classes.dynamicVideo}
            />
          )}
          {/* https://web.dev/lazy-loading-video/ */}
          {/* playsinline is necessary for autoplaying to occur in iOS.  */}
          <video    
            preload="auto"        
            autoPlay
            muted
            loop            
            playsInline
            className={classes.dynamicVideo}
            poster="../assets/images/dynamicVidPlaceholder.jpg"
          >
            <source src={PoolVideo} type="video/webm"/>
            <source src={PoolVideoAlt}  type="video/mp4"/>
          </video>  

          


        </>
      )}
      <Typography
        component="H1"
        color="inherit"
        align="center"
        variant="h1"
        className={classes.h1}
      > 
      
        EMSCulpt NEO: die revolutionäre nicht-invasive Körperformung

      </Typography>
      <Typography 
        component="H2"
        color="inherit" 
        align="center" 
        variant="h2" 
        marked="center" 
        className={classes.h5}>
        ESTETIESE <br/>Dr. med. Anna Maria Müller
      </Typography>

      <Button
        color="secondary"
        variant="contained"
        size="large"
        className={classes.button}
        // component={Link}
        href={matchesXS ? "tel:016090623273" : "mailto:info@extetiese.de?subject=Termin für EMSulpt NEO&body=Datenschutz: Ihre Daten werden nur für die Kontaktaufnahme verwendet und nicht gespichert. Bitte nehmen Sie mit mir Kontakt für eine Terminabsprache auf. Wunschtermine: ?"}
      >
        {matchesXS ?  <CallIcon/>:<MailOutlineIcon/>} &nbsp;
        Termin
        
      </Button>
      <Typography variant="body2" color="inherit" className={classes.more}>
      
      </Typography>
    </ProductHeroLayout>
  )
}

ProductHero.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(ProductHero)
