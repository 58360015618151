import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"
import Typography from "./Utils/Typography"
//import { StaticImage } from "gatsby-plugin-image"
import Image from "./Image"
//import {rawTheme} from "./UI/Theme"


const styles = theme => ({
  root: {
    display: "flex",
    overflow: "hidden",
    backgroundColor: theme.palette.secondary.light,
  },
  container: {
    marginTop: theme.spacing(15),
    marginBottom: theme.spacing(30),
    display: "flex",
    position: "relative",
  },
  item: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(0, 5),
  },
  image: {
    //width: 200,
    height:100,
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  body2:{
    fontSize: 20,
    fontWeight: 400,
  },
/*   curvyLines: {
    pointerEvents: "none",
    position: "absolute",
    top: -180,
  }, */
})

function EmsculptNEOAlleinstellungsmerkmale(props) {
  const { classes } = props

  return (
    <section className={classes.root}>

{/*       <Image
        src="Emsculpt-Neo_ICON_30-minutes_ENUS100.png"
        className="mx-auto shadow-xl"
        alt="Sunset Image"
        style={{
          border: "10px solid green",
        }}
      /> */}

      <Container className={classes.container} id="EMSCULPT-PRO-ALLEINSTELLUNGSMERKMALE" aria-label="Alleinstellungsmerkmale von EMSCULPT-NEO">

        <Grid container spacing={5}>

        <Grid item xs={12} md={4}>
            <div className={classes.item}>
            <Image
              src="Emsculpt-Neo_ICON_30-minutes_ENUS100.png"
              alt="30 Minuten"  
              className={classes.image}                          
              imgStyle={{ objectFit: 'contain' }}
            />                      

              <br/>
            <Typography variant="body2" className={classes.body2} style={{textAlign: 'justify'}}>
            Schaffen Sie 20.000 Sit-ups in einer halben Stunde?
            Mit EMSculpt NEO trainieren Sie effektiv Ihre Muskeln,
            dabei entspricht die Anzahl der Muskelkontraktionen in einer 
            Sitzung ca. 20.000 Sit-Ups.
            </Typography>

            </div>
          </Grid>          
          <Grid item xs={12} md={4}>
            <div className={classes.item}>
              <Image
                src="Emsculpt-Neo_ICON_25-More-muscle_ENUS100.png"
                alt="+25 % Muskelmasse"  
                className={classes.image}            
                imgStyle={{ objectFit: 'contain' }}
              />  

            <br/>
            <Typography variant="body2"  className={classes.body2}  style={{textAlign: 'justify'}}>
            Mit nur vier Sitzungen können Sie Ihr Muskel&shy;wachstum nachhaltig steigern.
            EMSculpt NEO ist sicher und ist von der amerikanischen Gesundheits&shy;behörde
            FDA klinisch getestet und zugelassen. Dabei hält die Wirkung bis zu 12 Monate an.

            </Typography>

            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.item}>
              <Image
                src="Emsculpt-Neo_ICON_30-Less-fat_ENUS100.png"
                alt="um bis zu 30 % Fettreduktion" 
                className={classes.image}        
                imgStyle={{ objectFit: 'contain' }}                     
              />  


            <br/>
            <Typography variant="body2"  className={classes.body2}  style={{textAlign: 'justify'}}>
            EMSculpt Neo unterstützt Sie effektiv beim Abbau Ihrer Fettpolster, z.B. am Bauch.
            So können Sie ohne Operation oder Fett-Weg-Spritze erfolge erzielen. Selbstverständlich ohne Ausfall&shy;zeiten. Direkt nach der Sitzung sind Sie wieder geschäfts&shy;fähig.

            </Typography>


            </div>
          </Grid>

        </Grid>
      </Container>
    </section>
  )
}

EmsculptNEOAlleinstellungsmerkmale.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(EmsculptNEOAlleinstellungsmerkmale)
