import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"
import Typography from "./Utils/Typography"

import PoolVideo from "../assets/videos/EMSculpt_applications.webm"
import PoolVideoAlt from "../assets/videos/EMSculpt_applications.mp4"



const styles = theme => ({
  root: {
    marginTop: theme.spacing(10),
    marginBottom: 0,
    display: "flex",
  },
  cardWrapper: {
    zIndex: 1,
  },
  card: {
    display: "flex",
    justifyContent: "center",
   
    backgroundColor: "white",
    padding: theme.spacing(8, 3),
  },
  cardContent: {
    maxWidth: 400,
  },
  textField: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "100%",   
  },
  button: {
    width: "100%",
  },
  imagesWrapper: {
    position: "relative",
  },

  image: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: "100%",
    maxWidth: 600,
  },

  
})

function EmsculptNeoFunktion(props) {
  const { classes } = props


  return (
    <Container className={classes.root} component="section" >
      <Grid container>
        <Grid item xs={12} md={6} className={classes.imagesWrapper}>


          <video    
            id="VIDEO-EMSCULPT-NEO-ANWENDUNGEN"
            aria-label="Video das die verschiedenen Körperzonen in den EMSCULPT NEO angezeigt werden kann darstellt: Bauch, Beine, Po, Waden und Arme"
            preload="auto"        
            autoPlay
            muted
            loop            
            playsInline
            className={classes.dynamicVideo}
            poster="../assets/images/dynamicVidPlaceholder.jpg"
            width="90%"
          >
            <source src={PoolVideo} type="video/webm"/>
            <source src={PoolVideoAlt}  type="video/mp4"/>
          </video>  

        </Grid>
        <Grid item xs={12} md={6} className={classes.cardWrapper}>
        <Typography variant="h4" marked="center" align="center" component="h2">
        EMSCULPT NEO: DIE IDEALE ERGÄNZUNG ZUR IHREM TRAINING
        </Typography >
        <article id="EMSCULPT-NEO-TRAINING-EFFEKTE" aria-label="Möglichkeiten der Anwendung von EMSCULPT NEO ihr persönliches Traing zu ergänzen" >
         <Typography  component={'article'} variant={'body2'}  align="justify">           
         EMSCULPT NEO®: ergänzt Ihr Training und ermöglicht Ihnen Fettabbau (-30%) und Muskelaufbau (+25%) auch in Zeiten der Pandemie! 
Klassische Einsatzgebiete sind die Problemzonen, wie Bauch (Sixpack) und Gesäß (Po-Lift mit Volumeneffekt, „BRAZILIAN BUTT“), aber auch Arme, Oberschenkel und Waden.
Mittels elektromagnetischer Felder (Tesla) werden die entsprechenden Muskelgruppen angeregt und dank der Radiofrequenz (RF) die Effizienz der Behandlung zusätzlich gesteigert.
Es handelt sich hierbei um eine echte technologische Revolution, die NICHT mit EMS (z.B. in Fitness-Studios angeboten) verwechselt werden darf(!): bei EMSCULPT NEO kontrahieren sich alle Muskelfasern des Muskels, auch sehr tiefe Muskelregionen werden erreicht, es drohen keine Nierenschäden, die Haut wird auch nicht verbrannt. 
Nicht nur das oberflächliche (subkutane) Fettgewebe wird sehr erfolgreich reduziert, sondern auch das tiefe (viszerale), besonders gesundheitsschädliche Fett!
Auch eine Rektusdiastase kann völlig non-invasiv behandelt werden.
Gleichzeitig wird die Haut gestrafft.
Selbstverständlich kann EMSCULPT NEO mit anderen Behandlungen kombiniert werden: mit sonstigen Methoden des non-invasiven Body Contouring sowie nach einer Fettabsaugung.
         </Typography>
         </article>
        </Grid>               
      </Grid>
    </Container>
  )
}

EmsculptNeoFunktion.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(EmsculptNeoFunktion)
