import React from "react"
import Layout from "../components/UI/Layout"
import Seo from "../components/Seo"
import ProductHero from "../components/ProductHero"
import EmsculptNEOAlleinstellungsmerkmale from "../components/EmsculptNEOAlleinstellungsmerkmale"
import EmsculptNeoImpressionen from "../components/EmsculptNeoImpressionen"
import EmsculptNeoPreise from "../components/EmsculptNeoPreise"
import EmsculptNEOFragenAntworten from "../components/EmsculptNEOFragenAntworten"
import EmsculptPointContact from "../components/EmsculptPointContact"
import EmsculptHerstellerInformationenBTL from "../components/EmsculptHerstellerInformationenBTL"
import EmsculptNeoFunktion from "../components/EmsculptNeoFunktion"
// import SemanticSideInformation from '../components/Semantic/SemanticSideInformation'

export default function Home() {
  return (
    <Layout>      
      {/* <Seo title="EMSCULPT NEO die ideale Ergänzung für ihr Fitnessprogramm, revolutionäre nicht-invasive Körperformung, jetzt neu in Bremen" /> */}
      {/* <SemanticSideInformation/> */}
      <Seo title="FITNESS und MUSKELN-ohne TRANING&#38;STUDIO?" />
      
      <ProductHero dynamic />
      <EmsculptNEOAlleinstellungsmerkmale />
      <EmsculptNeoImpressionen />
      <EmsculptNeoPreise />
      <EmsculptNeoFunktion/>
      <EmsculptNEOFragenAntworten />      
      <EmsculptHerstellerInformationenBTL />
      <EmsculptPointContact />

      
    </Layout>
  )
}
